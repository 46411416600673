import React from 'react'
import { LogoSVG } from './LogoSVG'

export const Preloader = () => (
  <div className="preloader-wrapper" id="preloader-wrapper">
    <div className="preloader" id="preloader">
      <div className="preloader-logo">
        <LogoSVG />
      </div>
    </div>
  </div>
)
