import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { HomepageBanner } from '../components/HomepageBanner'
import { components } from '../slices'

import { Preloader } from '../components/Preloader'

const HomeTemplate = ({ data }) => {
  if (!data) return null
  const doc = data.prismicHomepage.data

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      function revealP() {
        var reveal = document.querySelector('.banner-content')

        if (window.scrollY == 0) {
          reveal.classList.remove('banner-content-active')
        } else {
          reveal.classList.add('banner-content-active')
        }
      }
      window.addEventListener('scroll', revealP)

      return () => {
        window.removeEventListener('scroll', revealP)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      function hidePreloader() {
        const preloader = document.getElementById('preloader-wrapper')

        if (preloader) {
          setTimeout(() => {
            preloader.style.opacity = '0'
          }, 2500)
          setTimeout(() => {
            preloader.style.display = 'none'
          }, 3000)
        }
      }

      window.addEventListener(`load`, hidePreloader())

      return () => {
        window.removeEventListener(`load`, hidePreloader())
      }
    }
  }, [])

  return (
    <Layout>
      <Seo />
      <Preloader />
      <HomepageBanner
        kicker={doc.banner_kicker.text}
        title={doc.banner_title.text}
        ingress={doc.banner_ingress.text}
        description={doc.banner_description.richText}
        backgroundUrl={doc.banner_background.url}
      />
      <SliceZone slices={doc.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    prismicHomepage {
      _previewable
      data {
        banner_kicker {
          text
        }
        banner_title {
          text
        }
        banner_ingress {
          text
        }
        banner_description {
          richText
        }
        banner_background {
          url
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...HomepageDataBodyText
          ...HomepageDataBodyQuote
          ...HomepageDataBodyFullWidthImage
          ...HomepageDataBodyImageGallery
          ...HomepageDataBodyImageHighlight
          ...HomepageDataBodySectionTitle
          ...HomepageDataBodyTwoCol
          ...HomepageDataBodyCasesBlurbFullwidth
          ...HomepageDataBodyCasesBlurbThreecol
        }
      }
    }
  }
`

export default withPrismicPreview(HomeTemplate)
