import React from 'react'
import { PrismicRichText } from '@prismicio/react'

export const HomepageBanner = ({
  kicker,
  title,
  ingress,
  description,
  backgroundUrl,
}) => (
  <section
    className="homepage-banner"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url(${backgroundUrl})`,
    }}
  >
    <div className="banner-content container">
      <h4 className="banner-kicker">{kicker}</h4>
      <h2 className="banner-title">{title}</h2>
      <div className="banner-reveal">
        <h3 className="banner-ingress">{ingress}</h3>
        <PrismicRichText className="banner-description" field={description} />
      </div>
    </div>
  </section>
)
